.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-dropdown-toggle::after {
  content: none;
}

.modal-header {
  background-color: #495057;
  border-top: 5px solid black;
  border-left: 5px solid black;
  border-right: 5px solid black;
}
.modal-body {
  background-color: #495057;
  border-top:2px solid white;
  border-left: 5px solid black;
  border-right: 5px solid black;
}
.modal-body-nofooter {
  background-color: #495057;
  border-top:2px solid white;
  border-left: 5px solid black;
  border-right: 5px solid black;
  border-bottom: 5px solid black !important;
  border-radius: 5px;
}
.modal-footer {
  background-color: #495057 !important;
  border-bottom: 5px solid black;
  border-left: 5px solid black;
  border-right: 5px solid black;
}

.no-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.no-button:hover {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.no-button:checked {
  background: none;
  color: inherit;
  background-color: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.btn-block {
  width: 100%;
}

.small-alert {
  height: 25px;
  padding-top: 0;
}

.short-p {
  margin: 0;
}

.active-cursor {
  cursor: pointer;
}

.container-box {
  overflow: scroll;
  min-height: 130px;
  max-height: 130px;
  border: 2px solid black;
  border-top: none;
  border-radius: 2px;
  color: black !important;
  padding: 10px;
}

.container-box-dark {
  overflow: scroll;
  min-height: 130px;
  max-height: 130px;
  border: 2px solid black;
  border-top: none;
  border-radius: 2px;
  background-color: #212529;
  color: white;
  padding: 10px;
}

.table-border {
  border-bottom: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
}

.window-heading-bar {
  height: 30px;
  width: 100%;
  padding: -2px;
  border-bottom: .5px solid black;
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
  text-align: center;
  background-color: #212529;
  color: white;
}

.messages {
  height: 400px;
  overflow: auto;
}

.message-users {
  height: 400px;
  overflow: auto;
}

.message {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 1px !important;
}

.scroll-to-bottom {
  overflow:auto; 
  display:flex; 
  flex-direction:column-reverse;
}

.box-border {
  border: 2px solid white;
}

.existing-checkin {
  border: 2px solid rgba(0, 0, 0, 0.39);
  border-radius: 10px;
  background-color: rgba(217, 255, 0, 0.531);
  padding: 3px
}

.bottom-stats  {
  text-align: center;
  border: 2px solid rgba(0, 0, 0, 0.491);
  border-radius: 10px;
  background-color: rgba(0, 128, 0, 0.522);
  padding: 5px;
  max-width: 1000px;
}

.width-full {
  width: max-content;
}

.badge.bg-danger {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
}